import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
// React bootstrap:
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// Components:
import SiteFooter from '../Components/SiteFooter'
import SiteNav from '../Components/SiteNav'
// import SidePreview from '../Components/SidePreview'
// CSS:
import '../css/process.css'
// Assets:
// import founders from '../assets/Founders.jpg'

const Process = (props) => (
  <>
    <Helmet>
      <title>Focus Areas | Sullivan Stein Leadership Development</title>
      <meta
        name="description"
        content="Explore Sullivan Stein's key focus areas in leadership development, including executive coaching, organizational consulting, and educational partnerships."
      />
      <meta
        name="keywords"
        content="leadership coaching, executive development, educational consulting, organizational leadership, family partnerships"
      />
      <link rel="canonical" href="https://sullivanstein.com/focusareas" />
      <meta
        property="og:title"
        content="Focus Areas | Sullivan Stein Leadership Development"
      />
      <meta
        property="og:description"
        content="Explore our key focus areas in leadership development, including executive coaching and organizational consulting."
      />
      <meta property="og:url" content="https://sullivanstein.com/focusareas" />
      <meta
        name="twitter:title"
        content="Focus Areas | Sullivan Stein Leadership Development"
      />
      <meta
        name="twitter:description"
        content="Explore our key focus areas in leadership development, including executive coaching and organizational consulting."
      />
    </Helmet>
    <div className={`page process d-flex flex-column ${props.windowSize}`}>
      <SiteNav active="process" />
      <Container className="main">
        <Row>
          <Col
            lg={12}
            md={12}
            className="column-primary column-full-width pr-5">
            <h1 className="text-center mb-5">Focus Areas</h1>

            <h2>Focus Areas</h2>
            <hr />
            <strong>
              <ul className="list-unstyled">
                <li className="lead">
                  <a href="#advancement">Advancement</a>
                </li>
                <li className="lead">
                  <a href="#coaching">
                    Executive coaching and consulting for School Leaders and new
                    Heads of School
                  </a>
                </li>
                <li className="lead">
                  <a href="#enrollment_management">Enrollment Management</a>
                </li>
                <li className="lead">
                  <a href="#governance">Governance</a>
                </li>
                <li className="lead">
                  <a href="#leadership_teams">Leadership Teams</a>
                </li>

                <li className="lead">
                  <a href="#strategic_planning">Strategic Planning</a>
                </li>
                <li className="lead">
                  <a href="#succession_planning">Succession Planning</a>
                </li>
                <li className="lead">
                  <a href="#partnering_with_families">
                    Partnering with Families
                  </a>
                </li>
              </ul>
            </strong>
            <hr />

            <a name="advancement" href="#coaching" className="invisible">
              Advancement
            </a>
            <h3>Advancement</h3>
            <p>
              We review best practices and resources in the advancement area,
              including a focus on annual funds, capital and endowment campaigns
              and advancement office structure.
            </p>

            <a name="coaching" href="#coaching" className="invisible">
              Coaching
            </a>
            <h3>
              Executive coaching and consulting for School Leaders and new Heads
              of School
            </h3>
            <p>
              We develop strengths in leaders. This is done through monthly or
              more frequent conversations focusing on the areas of concern
              initiated by clients, ensuring they develop more effective
              leadership capacities.
            </p>

            <a
              name="enrollment_management"
              href="#enrollment_management"
              className="invisible">
              Enrollment Management
            </a>
            <h3>Enrollment Management</h3>
            <p>
              Enrollment Management consulting has become increasingly relevant
              to Independent School Boards, related to tuition and financial aid
              budgets, competing educational options, including charter schools,
              home-schooling, for-profit schools, and universal pre-K programs.
            </p>

            <a name="governance" href="#governance" className="invisible">
              Governance
            </a>
            <h3>Governance</h3>
            <p>
              We work with boards to strengthen the relationship between the
              Head of School and the trustees, a critical element of success in
              any education community. We assist Boards in recruiting and
              educating new trustees. We work with board chairs to prepare
              productive agendas. We facilitate board retreats incorporating
              emerging data on emerging trends.
            </p>

            <a
              name="leadership_teams"
              href="#leadership_teams"
              className="invisible">
              Leadership Teams
            </a>
            <h3>Leadership Teams</h3>
            <p>
              Administrative teams, departmental teams, and grade level leaders
              must work collaboratively. We review the dynamics of each team,
              analyse individual strengths and note how these can be leveraged
              on the leadership team.
            </p>

            <a
              name="strategic_planning"
              href="#strategic_planning"
              className="invisible">
              Strategic planning
            </a>
            <h3>Strategic planning</h3>
            <p>
              Through surveys, targeted focus groups, and 1:1 conversations with
              Board members, administration, faculty, parents and alumni, we
              discover the themes that will guide the school's creation of its
              strategic vision.
            </p>

            <a
              name="succession_planning"
              href="#succession_planning"
              className="invisible">
              Succession Planning
            </a>
            <h3>Succession Planning</h3>
            <p>
              When faced with the transition of a school head, we work closely
              with boards of trustees to guide and inform that most essential
              process.
            </p>

            <a
              name="partnering_with_families"
              href="#partnering_with_families"
              className="invisible">
              Partnering with Families
            </a>
            <h3>Partnering with Families</h3>
            <p>
              Providing guidance to families as they navigate school choice.
            </p>
          </Col>
          {/* <Col lg={3} md={4} className="column-secondary">
            <SidePreview title="The Team">
              <img
                alt="The Team, Patricia Hayot and Ellen C. Stein"
                className="img-fluid img-thumbnail mb-2"
                src={founders}
              />
              <p>
                In memory of Ann F Sullivan, Founder of SullivanStein Leadership
                Development, who passed away in 2024. She was an extraordinarily
                successful and much-beloved educator and leader. Her life's work
                inspired generations of learners and professional communities.
              </p>
            </SidePreview>
          </Col> */}
        </Row>
      </Container>
      <SiteFooter />
    </div>
  </>
)

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
})

export default connect(mapStateToProps)(Process)

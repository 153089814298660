import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../css/App.css'
import Home from './Home'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('App Error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="App error">
          <h1>Something went wrong.</h1>
          <p>Please try refreshing the page.</p>
        </div>
      )
    }

    return this.props.children
  }
}

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <div className={`App ${this.props.windowSize}`}>
          <Home />
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
})

export default connect(mapStateToProps)(App)

import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
// CSS:
import '../css/contact.css'
// React bootstrap:
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// Components:
import SiteNav from '../Components/SiteNav'
import SiteFooter from '../Components/SiteFooter'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = { validated: false }
  }
  componentDidMount() {
    document.body.classList.add('contact')
  }

  componentWillUnmount() {
    document.body.classList.remove('contact')
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Contact Us | Sullivan Stein Leadership Development</title>
          <meta
            name="description"
            content="Get in touch with the Sullivan Stein team for executive coaching, leadership development, and educational consulting services."
          />
          <meta
            name="keywords"
            content="contact leadership coaches, executive coaching services, educational consulting contact"
          />
          <link rel="canonical" href="https://sullivanstein.com/contact" />
          <meta
            property="og:title"
            content="Contact Us | Sullivan Stein Leadership Development"
          />
          <meta
            property="og:description"
            content="Get in touch with the Sullivan Stein team for executive coaching and leadership development services."
          />
          <meta property="og:url" content="https://sullivanstein.com/contact" />
          <meta
            name="twitter:title"
            content="Contact Us | Sullivan Stein Leadership Development"
          />
          <meta
            name="twitter:description"
            content="Get in touch with the Sullivan Stein team for executive coaching and leadership development services."
          />
        </Helmet>
        <div
          className={`page contact d-flex flex-column ${this.props.windowSize}`}>
          <SiteNav active="contact" />
          <Container className="main">
            <Row>
              <Col
                lg={9}
                md={8}
                className="column-primary column-full-width pr-5">
                <h1 className="text-center mb-5">Contact</h1>
                <p className="lead">Get in touch with us</p>
                <hr className="mb-4" />
                {/* ContactForm component temporarily disabled due to API issues
                <ContactForm success="false" />
                */}
                <div className="email-contacts">
                  <p>Please contact us directly via email:</p>
                  <ul>
                    <li>
                      <a href="mailto:ellencstein13@gmail.com">
                        Ellen Stein (ellencstein13@gmail.com)
                      </a>
                    </li>
                    <li>
                      <a href="mailto:patriciathayot@gmail.com">
                        Patricia Hayot (patriciathayot@gmail.com)
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
          <SiteFooter />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
})

export default connect(mapStateToProps)(Contact)

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import './css/index.css'
import AppRoutes from './routes'
import WindowDimensions from './Components/WindowDimenstions'

const store = configureStore()
const jsx = (
  <Provider store={store}>
    <WindowDimensions />
    <AppRoutes />
  </Provider>
)

ReactDOM.render(jsx, document.getElementById('root'))

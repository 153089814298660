import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setWindowSize } from '../actions/window'

const WindowDimensions = ({ setWindowSize }) => {
  useEffect(() => {
    const updateDimensions = () => {
      try {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight })
      } catch (error) {
        console.error('Error updating window dimensions:', error)
      }
    }

    // Initial update
    updateDimensions()

    // Add event listener
    window.addEventListener('resize', updateDimensions)

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [setWindowSize])

  return null
}

const mapDispatchToProps = (dispatch) => ({
  setWindowSize: (dimensions) => dispatch(setWindowSize(dimensions)),
})

export default connect(undefined, mapDispatchToProps)(WindowDimensions)

import React from 'react'
import { connect } from 'react-redux'
// React bootstrap:
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// Components:
import SiteFooter from '../../Components/SiteFooter'
import SiteNav from '../../Components/SiteNav'
// import SidePreview from '../../Components/SidePreview'
// Assets:
import PortraitPatricia from '../../assets/PortraitPatricia.jpg'
const PatriciaHayot = (props) => (
  <div className={`page bios d-flex flex-column ${props.windowSize}`}>
    <SiteNav active="bios" />
    <Container className="main">
      <Row>
        <Col lg={12} md={12} className="column-primary column-full-width">
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col lg={4}>
              <img
                src={PortraitPatricia}
                className="img-fluid img-thumbnail mt-2 mb-3"
                alt="Patricia Hayot"
              />
            </Col>
            <Col>
              <h1>Patricia Hayot</h1>
              <p className="lead">
                Patricia Hayot, Ph.D., a consummate educator with more than 40
                years of experience, most recently served for seventeen years as
                the Head of School at The Chapin School, New York City, for
                which she now serves as Head Emerita. She has taught in the
                United States, Geneva, Switzerland, and Paris, France. Prior to
                Chapin, she served for a decade as Head of School at the
                International School of Paris, a K–12 school offering the
                International Baccalaureate, and for eighteen years as Head of
                School at The Columbus School for Girls.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Dr. Hayot has held leadership positions in a variety of
                educational associations, including the International Coalition
                of Girls Schools (ICGS); The Independent Schools Admissions
                Association of Greater New York (ISAAGNY); Heads Collegiate
                Forum (HCF); Heads and Principals Association (HPA); and The
                Educational Collaborative of International Schools (ECIS). She
                has served on or chaired over a dozen school accreditations,
                both here and in Europe.
              </p>
              <p>
                As a trustee at Robert College in Istanbul, Türkiye since 2017,
                she serves as chair of the Governance Committee and member of
                the Executive Committee. As a trustee since 2018 for The Academy
                for Teachers (AfT), she works to advance the mission to support
                public and independent school educators through world-class
                learning experiences.
              </p>
              <p>
                A former Klingenstein Fellow at Teachers College, Dr. Hayot
                earned a Ph.D. in educational psychology from the University of
                Michigan and bachelor’s and master’s degrees from Marquette
                University.
              </p>
              <p>
                She has been engaged throughout her career in strategic
                leadership within a variety of national and international
                educational communities, inspiring professionals at all levels,
                coaching and challenging aspiring leaders, and partnering with
                boards of trustees to achieve strategic goals.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <SiteFooter />
  </div>
)

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
})

export default connect(mapStateToProps)(PatriciaHayot)

import React from 'react'
import { connect } from 'react-redux'
// React bootstrap:
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// Components:
import SiteFooter from '../../Components/SiteFooter'
import SiteNav from '../../Components/SiteNav'
import SidePreview from '../../Components/SidePreview'
// Assets:
import PortraitEllen from '../../assets/PortraitEllen.jpg'

const EllenStein = (props) => (
  <div className={`page bios d-flex flex-column ${props.windowSize}`}>
    <SiteNav active="bios" />
    <Container className="main">
      <Row>
        <Col lg={12} md={12} className="column-primary column-full-width">
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col lg={4}>
              <img
                src={PortraitEllen}
                className="img-fluid img-thumbnail mt-2 mb-3"
                alt="Ellen C. Stein"
              />
            </Col>
            <Col>
              <h1>Ellen C. Stein</h1>
              <p className="lead">
                Currently a partner in Sullivan Stein Leadership Development,
                Ellen Stein recently completed 17 years as Head of The Dalton
                School, a coeducational K-12 private school in Manhattan. For
                the six years prior to that she was Dalton's Associate Head of
                School and Director of the Lower School.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                She started her career in the Corporate Finance Department at
                Donaldson, Lufkin and Jenrette and after several years went into
                teaching. She taught at The Brearley School and then moved to
                Friends Seminary where she taught Latin and worked in
                administration as the Director of Admissions, the Director of
                Development and the Vice Principal.
              </p>
              <p>
                Ellen is currently a member of the Board of Trustees of
                Deerfield Academy and a Commisioner of the New York State
                Association of Independent Schools. She served on the Board of
                the Cathedral School of St. John The Divine, Columbia Medical
                Center Advisory Board, Grace Church School Board, the Board of
                the Guild of Independent Schools and was President of the Board
                of Trustees of The Heads Network. In addition, Ellen is a
                current member of Headmistresses of the East (HMAE), The
                Headmasters Association, The Heads Network, and Country Day
                School Headmasters Association.
              </p>
              <p>
                Ellen is a graduate of The Dalton School and received her degree
                in History and Secondary Education at the University of
                Pennsylvania. She received her MBA from Columbia Business
                School. She completed the Columbia University Coaching Program,
                Front-End intensive and 9 months Practicum 2018-19.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <SiteFooter />
  </div>
)

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
})

export default connect(mapStateToProps)(EllenStein)

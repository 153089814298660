import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// React bootstrap:
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
// Components:
import SiteFooter from '../Components/SiteFooter'
import SiteNav from '../Components/SiteNav'
import SidePreview from '../Components/SidePreview'
// CSS:
import '../css/bios.css'
// Assets:
// import founders from '../assets/FoundersHorizontal.jpg'
import ann from '../assets/PortraitAnn.jpg'
import ellen from '../assets/PortraitEllen.jpg'
import patricia from '../assets/PortraitPatricia.jpg'
const Bios = (props) => (
  <>
    <Helmet>
      <title>Our Team | Sullivan Stein Leadership Development</title>
      <meta
        name="description"
        content="Meet the expert team at Sullivan Stein - experienced leaders in executive coaching, organizational development, and educational leadership."
      />
      <meta
        name="keywords"
        content="leadership team, executive coaches, educational consultants, leadership development experts"
      />
      <link rel="canonical" href="https://sullivanstein.com/bios" />
      <meta
        property="og:title"
        content="Our Team | Sullivan Stein Leadership Development"
      />
      <meta
        property="og:description"
        content="Meet our expert team of experienced leaders in executive coaching and educational leadership development."
      />
      <meta property="og:url" content="https://sullivanstein.com/bios" />
      <meta
        name="twitter:title"
        content="Our Team | Sullivan Stein Leadership Development"
      />
      <meta
        name="twitter:description"
        content="Meet our expert team of experienced leaders in executive coaching and educational leadership development."
      />
    </Helmet>
    <div className={`page bios d-flex flex-column ${props.windowSize}`}>
      <SiteNav active="bios" />
      <Container className="main">
        <Row>
          <Col
            lg={9}
            md={8}
            className="column-primary column-border-right pr-5">
            <h1 className="text-center mb-5">The Team</h1>
            {/* <Row>
              <Col className="d-flex justify-content-center">
                <Figure>
                  <Figure.Image
                    alt="The Founders, Ann Sullivan and Ellen C. Stein"
                    className="img-fluid img-thumbnail"
                    src={founders}
                  />
                  <Figure.Caption>
                    The founders, Ellen C. Stein and Ann Sullivan
                  </Figure.Caption>
                </Figure>
              </Col>
            </Row> */}
            <Row className="mt-4">
              <Col md={12} lg={6}>
                <h2>
                  <Link to="bios/ellenstein">Ellen C. Stein</Link>
                </h2>
                <Figure>
                  <Link to="/bios/ellenstein">
                    <Figure.Image
                      alt="Ellen C. Stein"
                      className="img-fluid img-thumbnail"
                      src={ellen}
                    />
                  </Link>
                </Figure>
                <p>
                  Currently a partner in Sullivan Stein Leadership Development,
                  Ellen Stein completed 17 years as Head of The Dalton School, a
                  coeducational K-12 private school in Manhattan. For the six
                  years prior to that she was Dalton's Associate Head of School
                  and Director of the Lower School&hellip; <br />
                  <Link to="/bios/ellenstein">{`>`} More</Link>
                </p>
              </Col>
              <Col>
                <h2>
                  <Link to="bios/patriciahayot">Patricia Hayot</Link>
                </h2>
                <Figure>
                  <Link to="/bios/patriciahayot">
                    <Figure.Image
                      alt="Patricia Hayot"
                      className="img-fluid img-thumbnail"
                      src={patricia}
                    />
                  </Link>
                </Figure>
                <p>
                  Patricia Hayot, Ph.D., a consummate educator with more than 40
                  years of experience, most recently served for seventeen years
                  as the Head of School at The Chapin School, New York City, for
                  which she now serves as Head Emerita. She has taught in the
                  United States, Geneva&hellip; <br />
                  <Link to="/bios/patriciahayot">{`>`} More</Link>
                </p>
              </Col>
            </Row>
          </Col>
          <Col lg={3} md={4} className="column-secondary">
            <SidePreview title="In Memory">
              <img
                alt="The Team, Patricia Hayot and Ellen C. Stein"
                className="img-fluid img-thumbnail mb-2"
                src={ann}
              />
              <p>
                In memory of Ann F Sullivan, Founder of SullivanStein Leadership
                Development, who passed away in 2024. She was an extraordinarily
                successful and much-beloved educator and leader. Her life's work
                inspired generations of learners and professional communities.
              </p>
            </SidePreview>
          </Col>
        </Row>
      </Container>
      <SiteFooter />
    </div>
  </>
)

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
})

export default connect(mapStateToProps)(Bios)

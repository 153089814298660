import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
// React bootstrap:
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// Css:
import '../css/missionstatement.css'
// Components:
import SiteNav from '../Components/SiteNav'
import SiteFooter from '../Components/SiteFooter'
import SidePreview from '../Components/SidePreview'
// Assets:
import founders from '../assets/Founders.jpg'

class MissionStatement extends React.Component {
  componentDidMount() {
    document.body.classList.add('missionstatement')
  }

  componentWillUnmount() {
    document.body.classList.remove('missionstatement')
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Mission Statement | Sullivan Stein Leadership Development
          </title>
          <meta
            name="description"
            content="Sullivan Stein's mission is to provide transformative leadership coaching and consulting that empowers educational leaders to excel in their roles and create positive change."
          />
          <meta
            name="keywords"
            content="leadership mission, executive coaching philosophy, educational leadership, leadership development"
          />
          <link
            rel="canonical"
            href="https://sullivanstein.com/missionstatement"
          />
          <meta
            property="og:title"
            content="Mission Statement | Sullivan Stein Leadership Development"
          />
          <meta
            property="og:description"
            content="Our mission is to provide transformative leadership coaching and consulting that empowers educational leaders."
          />
          <meta
            property="og:url"
            content="https://sullivanstein.com/missionstatement"
          />
          <meta
            name="twitter:title"
            content="Mission Statement | Sullivan Stein Leadership Development"
          />
          <meta
            name="twitter:description"
            content="Our mission is to provide transformative leadership coaching and consulting that empowers educational leaders."
          />
        </Helmet>
        <div
          className={`page missionstatement d-flex flex-column ${this.props.windowSize}`}>
          <SiteNav active="missionstatement" />
          <Container className="main">
            <Row>
              <Col
                lg={12}
                md={12}
                className="column-primary column-full-width pr-5">
                <h1 className="text-center mb-5">Mission Statement</h1>
                <p className="lead">
                  Drawing on their experiences as Heads and Administrators in
                  Independent Schools and decades on nonprofit Boards, Ellen
                  Stein and Patricia Hayot are uniquely qualified to consult on
                  many facets of school leadership, women in leadership
                  positions, and governance. They coach and advise Heads and
                  Assistant Heads of Schools, Directors of Enrollment, College
                  Counselors, Institutional Advancement, as well as Division
                  Heads, Department Chairs, Board Chairs, and Trustee Leaders.
                  They bring extensive experience and expertise to strategic and
                  succession planning. Additionally, they partner with families
                  to assist in school choice decisions.
                </p>
              </Col>
              {/* <Col lg={3} md={4} className="column-secondary">
                <SidePreview title="The Team">
                  <img
                    alt="The Team, Patricia Hayot and Ellen C. Stein"
                    className="img-fluid img-thumbnail mb-2"
                    src={founders}
                  />
                  <p>
                    In memory of Ann F Sullivan, Founder of SullivanStein
                    Leadership Development, who passed away in 2024. She was an
                    extraordinarily successful and much-beloved educator and
                    leader. Her life's work inspired generations of learners and
                    professional communities.
                  </p>
                </SidePreview>
              </Col> */}
            </Row>
          </Container>
          <SiteFooter />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  windowWidth: state.window.width,
  windowHeight: state.window.height,
  windowSize: state.window.size,
})

export default connect(mapStateToProps)(MissionStatement)
